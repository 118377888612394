import React from 'react'
import { director } from '../../assets'

export default function ExecutiveSummary() {
  return (
    <section className=''>
      <div class="container mx-auto p-2 lg:py-5 lg:px-32">
        <h1 class="text-2xl text-center md:text-4xl text-green-700 font-semibold uppercase p-3">Executive Summary</h1>
        <div className='flex justify-center items-end'>
          <div className='w-[100px] h-[3px] rounded-lg bg-yellow-400 '></div>
        </div>
        <div class="flex">
          <div class="">
            <p class="text-justify leading-5 lg:leading-10 p-2 lg:text-lg text-sm">The Association was incorporated and registered with the company registration Act 1963 (Act 179) on operational division in seeking welfare of its members. Foster unity among members, providing loans by GNACOFA Co-operative Credit Union Ltd to its members providently or productive purposes at fair competitive rate of interest, promoting progress of cocoa business and advance development in the farming communities, training member on new and better agricultural techniques to improve cocoa farming in the country.</p>
            <div className='flex justify-center items-center flex-col  '>
              <div className='rounded-full border-green-700 border-2 w-[100px] h-[100px] '>
                <img src={director} className='object-cover rounded-full w-[100px] h-[100px]' alt='presidents' />
              </div>
              <div className='text-center'>
                <p>Stephenson Anane Boateng</p>
                <p className='font-bold'>[President]</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
