import {React, useEffect} from 'react'
import welcome from '../../../assets/images/welcome.jpg'
import organograph from '../../../assets/images/orga.jpg'

const Structure = () => {
  useEffect(() => {
      document.title="Our Structure"
  })
  return (
    <section class="">
      <title>Our Structure</title>
      <div class=" relative">
        {/* <!-- General About us --> */}
        <div class="lg:h-[61svh] overflow-y-scroll">
          <div class="p-2 ">
            <img src={organograph} class="w-full h-[100svh]" alt=""/>
          </div>
        </div>
      </div>
    </section>
  )
}
export default Structure
