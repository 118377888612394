import React from 'react'

const YoutubeFeed = () => {
  
  return (
    <section class="bg-gray-100">
      <div class="">
        <div class="container mx-auto lg:px-32 px-2">
          <div className='flex justify-center items-end'>
            <div className='w-[100px] h-[3px] rounded-lg bg-yellow-400 '></div>
          </div>
          <div class="w-full py-3">
            <iframe class="rounded-lg w-full h-96" src="https://www.youtube.com/embed/Hlo4ncKODaI?si=xwB4YeYVeaEPsgeP" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            {/* <iframe class="rounded-lg w-full h-96" src="https://www.youtube.com/watch?v=tkcOSfRv5iM"></iframe> */}
          </div>
          <div class="flex  overflow-x-scroll gap-5 p-3">
            
            {/* {
              videos.map((video) => {
                video.length > 0 ? (
                  <div className=''>
                    
                    <iframe width="520" height="315" class="rounded-lg" src={video.vidlink}></iframe>
                    <div className='text-center'>Title of Video: {video.title} </div>
                  </div>
                ) : (
                  <div class="flex justify-center items-center"><h1 class="text-center"> No video uploaded to the data base</h1></div>
                )
              }
              )
            }
               */}
          </div>
          <h1 class="font-['Gugi'] text-xl font-bold text-green-600 text-center">Whats new on our <span className='text-red-700'>youtube </span>channel</h1>
          <h1 class="p-3 text-center">please click on the link  <a class="text-red-500 hover:text-red-800" href="https://www.youtube.com/@gnacofatv8488">https://www.youtube.com/@gnacofatv8488</a> to watch our lastest and most educative videos on our youtube platform </h1>
        </div>
      </div>
    </section>
  )
}

export default YoutubeFeed
