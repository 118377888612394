import React, { useState } from 'react';
import './navbar.css';
import brand_logo from '../../assets/icons/logo.png';

const Navbar = () => {
  const [visible, setVisible] = useState(false)
  const [toggle, setToggle] = useState()
  function showMenu(id){
    setToggle(id)
  }

  const handler = () => {
    setVisible(visible ? false : true);
  }
  return (
    <section class="sticky top-0 z-10 drop-shadow-lg bg-green-900 text-white lg:bg-green-900 border-b-2 lg:border-green-900 ">
      <div class="lg:container lg:mx-auto lg:px-32">
        <div class="header flex justify-between items-center gap-5 px-2 lg:px-5">
          <div class="p-1">
            <a href="/" className='flex items-center gap-x-0'>
              <img class="lg:w-16 lg:h-16 w-[50px] h-[50px] rounded-full p-1" src={brand_logo} alt='logo' />
              <div className='l text-white font-bold leading-3'>
                <h1>Ghana</h1>
                <h1>National Cocoa</h1>
                <h1>Farmers Association</h1>
              </div>
            </a>
          </div>
          {visible ? (
              <div className='absolute top-14 right-0 bg-black bg-opacity-96 backdrop-blur-sm'>
                <nav class="nav-menu">
                  <ul className='nav-ul'>
                    <li className='nav-li'><a href="/" class="active:border-b-2 active:border-b-green-700 focus:text-green-600 active:"><i class="fa-solid fa-house-chimney-window mr-2"></i>Home</a></li>
                    <li onClick={() => showMenu(1)} className='nav-li'><a href="#" className='link_flex'>gnacofa<i class="fa-solid fa-angle-down ml-2"></i></a>
                      <ul className={toggle === 1 ? 'show_cont' : 'hide_cont'}>
                        <li><a href='/about/about-us'>About us</a></li>
                        <li><a href="/about/our-management">Our Management</a></li>
                        <li><a href="/about/our-structure">Our Structure</a></li>
                      </ul>
                    </li>
                    <li className='nav-li'><a href="/statistics" class="focus:border-b-2 focus:border-b-green-700 focus:text-green-600">Statistical Analysis</a></li>
                    <li onClick={() => showMenu(2)} className='nav-li'><a href="#" className='link_flex'>Resources<i class="fa-solid fa-angle-down ml-2"></i></a>
                      <ul className={toggle === 2 ? 'show_cont' : 'hide_cont'}>
                        <li><a href="/resources/forms">Forms</a></li>
                        <li><a href="/resources/annual-reports">Annual GNCOFA Reports</a></li>
                        <li><a href="/resources/press-release">Press Releases</a></li>
                        <li><a href="/resources/newsletters">Newsletters</a></li>
                      </ul>
                    </li>
                    <li onClick={() => showMenu(3)} className='nav-li'><a href="#" className='link_flex'>Media<i class="fa-solid fa-angle-down ml-2"></i></a>
                      <ul className={toggle === 3 ?'show_cont':'hide_cont'}>
                        <li><a href="/media/news">News</a></li>
                        <li><a href="/media/Events">Events</a></li>
                        <li><a href="/media/gallery">Gallery</a></li>
                        <li><a href="/media/video">Videos</a></li>
                      </ul>
                    </li>
                    <li className='nav-li'><a href="/contact">Contact us</a></li>
                  </ul>
                </nav>
              </div>
            ):<></>
          }
          <nav class="navbar hidden lg:block">
            <ul className='md:space-x-2 '>
              <li><a href="/" class="active:border-b-2 active:border-b-green-700 focus:text-green-600 active:"><i class="fa-solid fa-house-chimney-window mr-2"></i>Home</a></li>
              <li><a href="">gnacofa <i class="fa-solid fa-angle-down ml-2"></i></a>
                <ul class="divide-y">
                  <li><a href='/about/about-us'>About us</a></li>
                  <li><a href="/about/our-management">Our Management</a></li>
                  <li><a href="/about/our-structure">Our Structure</a></li>
                </ul>
              </li>
              <li><a href="/statistics" class="focus:border-b-2 focus:border-b-green-700 focus:text-green-600">Statistical Analysis</a></li>
              <li><a href="#">Resources<i class="fa-solid fa-angle-down ml-2"></i></a>
                <ul className='divide-y'>
                  <li><a href="/resources/forms">Forms</a></li>
                  <li><a href="/resources/annual-reports">Annual GNCOFA Reports</a></li>
                  <li><a href="/resources/press-release">Press Releases</a></li>
                  <li><a href="/resources/newsletters">Newsletters</a></li>
                </ul>
              </li>
              <li><a href="#">Media<i class="fa-solid fa-angle-down ml-2"></i></a>
                <ul className='divide-y'>
                  <li><a href="/media/news">News</a></li>
                  <li><a href="/media/Events">Events</a></li>
                  <li><a href="/media/gallery">Gallery</a></li>
                  <li><a href="/media/video">Videos</a></li>
                </ul>
              </li>
              <li><a href="/contact">Contact us</a></li>
            </ul>
          </nav>
          <div class="hidden">
            <form action="#" method="POST">
              <div class="rounded-full border border-black px-1">
                <input type="search" name="search" id="search" placeholder="Search" class="text-sm focus:outline-none border" />
                <button onClick={() => handler(1)} class=" px-1 hover:text-gray-800"><i class="fa-sharp fa-solid fa-magnifying-glass border-l border-black px-2"></i></button>
              </div>
            </form>
          </div>
          <div className='lg:hidden mr-3 flex items-center gap-x-2'>
            <button class=" px-1 hover:text-gray-800"><span class="material-symbols-outlined">search</span></button>
            <button onClick={() => handler(2)} className=''><span class="material-symbols-outlined">menu</span></button>
          </div>
        </div>
      </div>
      
    </section>
  )
}

export default Navbar