import React from 'react'
import brand_logo from '../../assets/icons/logo.png';

const Footer = () => {
  return (
    <section class="bg-green-900 ">
      <div class="lg:container lg:mx-auto lg:px-36 bg-green-900 font-['tahoma'] lg:py-5 px-5 py-5">
        <div class="flex flex-wrap justify-between items-center  gap-x-10 text-sm text-white">          
          <div class="flex lg:justify-center mb-3">
            <div className={`lg:flex flex-col lg:justify-center lg:items-center`}>
              <div class="">
                <div className='flex justify-center items-center'>
                  <img class="ml-25 w-20 h-20  rounded-full p-1" src={brand_logo} alt='logo' />
                </div>
                <h6 class="mb-3">&copy; 2022, GNACOFA: <span>All Rights Reserved</span></h6>
              </div>
              <div class="lg:flex flex-col lg:justify-center items-center">
                <label for="title" class="text-red-500 text-sm font-bold">Follows on Our Social Media Platforms</label>
                <div class=" flex gap-5 lg:text-lg">
                  <a href="#"><i class="fa-brands fa-whatsapp"></i></a>
                  <a href="#"><i class="fa-brands fa-twitter"></i></a>
                  <a href="#"><i class="fa-brands fa-facebook"></i></a>
                  <a href="#"><i class="fa-brands fa-youtube"></i></a>
                </div>
              </div>
            </div>
          </div>
          <div className='lg:flex lgjustify-end  flex-row-2 lg:gap-x-16 gap-2'>
            <div class="space-y-2">
              <label for="title" class="text-red-500 font-bold text-lg">Resources</label>
              <h1 class="hover:text-white text-gray-400"><a href="/resources/forms">Forms</a></h1>
              <h1 class="hover:text-white text-gray-400"><a href="/resources/annual-reports">Annaul Reports</a></h1>
              <h1 class="hover:text-white text-gray-400"><a href="/resources/newsletters">Newsletters</a></h1>
              <h1 class="hover:text-white text-gray-400"><a href="/resources/press-release">Press Release</a></h1>
            </div>
            <div class="space-y-2">
              <label for="title" class="text-red-500 font-bold text-lg">Helpful Links</label>
              <h1 class="hover:text-white text-gray-400"><a href="/faq">FAQ</a></h1>
              <h1 class="hover:text-white text-gray-400"><a href="/about/about-us">About</a></h1>
              <h1 class="hover:text-white text-gray-400"><a href="/about/our-management">Our Management</a></h1>
              <h1 class="hover:text-white text-gray-400"><a href="/about/our-structure">Structure & Organograph</a></h1>
              <h1 class="hover:text-white text-gray-400"><a href="/media/news">News</a></h1>
              <h1 class="hover:text-white text-gray-400"><a href="/media/events">Events</a></h1>
              <h1 class="hover:text-white text-gray-400"><a href="/media/gallery">Gallery</a></h1>
              <h1 class="hover:text-white text-gray-400"><a href="/media/video">Video</a></h1>
            </div>
            <div class="space-y-2">
              <label for="title" class="text-red-500 font-bold text-lg">External Links</label>
              <h1 class="hover:text-white text-gray-400"><a href="https://portals.gnacofa.org" target="_blank" rel="noopener noreferrer">GNACOFA Portals Login</a></h1>
              <h1 class="hover:text-white text-gray-400"><a href="https://gnacofaccu.gnacofa.org" target="_blank" rel="noopener noreferrer">Gnacofa Co-operative Credit Union</a></h1>
            </div>
            <div class="space-y-2 text-gray-400 text-xs">
              <label for="title" class="text-red-500 font-bold text-lg">Locate us</label>
              <h1 class="mb-2"><i class="fa-solid fa-location-dot mr-2"></i> Ghana National Cocoa Farmers Association <br /> <span class="ml-6">P. O. Box, KS 5984</span><br /><span class="ml-6">Adum - Kumasi, Ghana</span></h1>
              <h1 class=" mb-2"><i class="fa-solid fa-phone mr-2"></i>Office: +233 (0) 32 219 4038<br /> <span class="ml-6">Field: +233 (0) 55 858 7441</span><br /><span class="ml-6">Farmers Hotline: +233 (0) 55 858 7441</span></h1>
              <h1 class="hover:text-white"><i class="fa-solid fa-envelope mr-2"></i><a href="mailto:president.gancofa@outlook.com">president@gnacofa.org</a><br /> <span class="ml-6"><a href="mailto:president.gancofa@outlook.com">president.gnacofa@outlook.com</a></span><br /><span class="ml-6"><a href="mailto:president.gancofa@outlook.com">info@gnacofa.org</a></span></h1>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap lg:justify-between text-sm text-white  pt-4">
          <div class="">
            <div class="text-sm ">
              <div class="flex flex-wrap gap-x-2">
                <h1 class="hover:text-white text-gray-300 underline"><a href="#">Terms & Condition</a></h1>
                <h1 class="hover:text-white text-gray-300 underline"><a href="#">Cookie Policy</a></h1>
                <h1 class="hover:text-white text-gray-300 underline"><a href="#">Privacy Policy</a></h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default Footer