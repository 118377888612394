import React from 'react'
import { useParams } from 'react-router-dom';
import Events from '../../constants/Events';

export default function EventDetails() {
  const { title } = useParams();
  const event = Events.find((event) => event.title === decodeURIComponent(title));

  if (!event) {
    return <div>event not found</div>;
  }
  return (
    <div className={`container mx-auto lg:py-5 lg:px-32`}>
      <div className={`min-h-screen overflow-y-scroll`}>
        <div className={`flex justify-between items-center border-b p-2`}>
          <div className={``}>
            <h1 className={`text-2xl font-bold`}>{event.title}</h1>
          </div>
          <h4 className={`text-sm text-gray-400`}>Posted: {event.date}</h4>
        </div>
        <div className={`p-2`}>
          <p className={`text-lg`}>{event.description}</p>
          <h6 className={``}>Date: {event.date}</h6>
          <h6 className={``}>Location: {event.location}</h6>
          <h6 className={``}>Time: {event.time}</h6>
        </div>
      </div>
    </div>
  )
}
