import React from 'react'
import { ghana_gnacofa_img } from '../../assets'

const Advert = () => {
  return (
    <section>
      <div class="container mx-auto lg:px-32 p-3 lg:py-5">
        <div class="rounded-xl p-5 bg-gray-100 flex justify-center items-center">
          <div class="flex flex-wrap lg:flex-row justify-center items-center">
            <div>
              <h1 class="text-xl lg:text-4xl">Want to become member of GNACOFA?</h1>
              <div class="flex flex-wrap lg:flex-row gap-5 justify-center items-center py-5">
                <a href="/resources/forms" class="w-64 border border-gray-500 text-center hover:bg-green-700 hover:text-white p-3 rounded-full">Become a Member</a>
                <a href="/about/about-us" class="w-64 border border-gray-500 text-center hover:bg-green-700 hover:text-white p-3 rounded-full">How we operate</a>
              </div>
            </div>
            <div class="flex justify-center">
              <img src={ghana_gnacofa_img} class="" width="50%" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Advert
