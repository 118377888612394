import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { welcome } from '../../assets';
import Events from '../../constants/Events';

export default function EventPage() {
  useEffect(() => {
    document.title = "Events";
  })
  const MonthName = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
  const date = new Date()
  const currentMonthIndex = date.getMonth();
  const currentMonth = MonthName[currentMonthIndex];
  const navigate = useNavigate();
  const [tab, setTabs] = useState('All')
  return (
    <section>
      <div class="lg:h-96 overflow-hidden relative">
        <img src={welcome} class="block w-full  brightness-50" width="10%" alt="" />
        <div class="px-5 absolute top-[100px] lg:top-20 left-[5px] lg:left-64">
          <h1 class="text-3xl font-bold text-green-600 shadow-md font-['Arial'] w-full uppercase">Events</h1>
        </div>
      </div>
      <div className='lg:container lg:mx-auto lg:px-36 h-[40rem]'>
        {/* buttons */}
        <div className={`flex gap-x-5 p-5 border-b-2 border-gray-300`}>
          {DisTabs(tab, setTabs, 'All')}
          {DisTabs(tab, setTabs, 'current Month')}
          {DisTabs(tab, setTabs, 'Previous Month')}
        </div>
        <div className='p-5'>
          {
            Events.length > 0 ?
              (Events.map((event) => (
                <div>
                  {tab === 'All' && 
                    (
                      event.date_created < date.getDate() ? (
                        <buttom key={event.id} className='flex items-center gap-4 hover:bg-green-200 p-2 hover:text-black hover:rounded-lg hover:cursor-pointer' onClick={() => navigate(`/event/details/${event.id}`)}>
                          <div className='w-[20px] h-[20px] rounded-full bg-green-500'></div>
                          <div>
                            <h3 class="text-green-700 text-sm font-semibold">{event.title}</h3>
                            <p class="text-green-500 text-xs">{event.date} {event.time}</p>
                            <p class="text-green-500 text-xs">{event.location}</p>
                          </div>
                        </buttom>
                      ) : (
                        <h1 className={``}>No upcoming events for {currentMonth}</h1>
                      )
                    )
                  }
                  {
                    tab === 'current Month' && (
                      event.date.split('-')[1] === (currentMonthIndex + 1).toString() && (
                        <buttom key={event.id} className='flex items-center gap-4 hover:bg-green-200 p-2 hover:text-black hover:rounded-lg hover:cursor-pointer' onClick={() => navigate(`/dynamic/${event.id}`)}>
                          <div className='w-[20px] h-[20px] rounded-full bg-green-500'></div>
                          <div>
                            <h3 class="text-green-700 text-sm font-semibold">{event.title}</h3>
                            <p class="text-green-500 text-xs">{event.date} {event.time}</p>
                            <p class="text-green-500 text-xs">{event.location}</p>
                          </div>
                        </buttom>
                      )
                    )
                  }
                  {
                    tab === 'previous Month' && (
                      event.date.split('-')[1]!== (currentMonthIndex + 1).toString() && (
                        <buttom key={event.id} className='flex items-center gap-4 hover:bg-green-200 p-2 hover:text-black hover:rounded-lg hover:cursor-pointer' onClick={() => navigate(`/dynamic/${event.id}`)}>
                          <div className='w-[20px] h-[20px] rounded-full bg-green-500'></div>
                          <div>
                            <h3 class="text-green-700 text-sm font-semibold">{event.title}</h3>
                            <p class="text-green-500 text-xs">{event.date} {event.time}</p>
                            <p class="text-green-500 text-xs">{event.location}</p>
                          </div>
                        </buttom>
                      )
                    )
                  }
                </div>
              ))
              ) : (
                <div className={``}>
                  <h1 className={``}>No Scheduled Events for {currentMonth}</h1>
                </div>
              )
          }
        </div>
      </div>
    </section>
  )
}

const DisTabs = (tab, setTabs,title) => {
  return (
    <div className={`flex gap-x-2`}>
      <button onClick={()=>{setTabs(title)}} className={`hover:text-black text-gray-400 text-lg ${tab === title ? 'font-bold border-b-4 border-black text-black' : ''}`}>{title }</button>
    </div>
  );
} 