import React,{useEffect} from 'react'
import projectsData from '../../constants/projects';
import { useNavigate } from 'react-router-dom';

const Projects = () => {
  useEffect(() => {
    document.title = "Projects"
  })
  const navigate = useNavigate();
  return (
    <section class="bg-gray-100">
      <div class="container mx-auto lg:py-5 lg:px-32 w-full">
        <div class="text-center text-4xl text-green-700 font-semibold py-">
          <h1 class="font-['Gugi'] uppercase">Projects</h1>
          <div className='flex justify-center items-end'>
            <div className='w-[100px] h-[3px] rounded-lg bg-yellow-400 '></div>
          </div>
        </div>
        <div class="flex flex-wrap mt-5">
          {projectsData.length > 0 ? (
              projectsData.map((project) =>
                <div key={project.title} class="p-4 sm:w-1/2 lg:w-96 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300">
                  <div class=" border-2 border-gray-200 border-opacity-60 rounded-lg shadow-xl overflow-hidden">
                    <button onClick={()=>navigate(`/project/detail/${encodeURIComponent(project.title)}`)}>
                      <img class="lg:h-44 md:h-48 w-full object-cover object-center" src={project.image} className="brigtness-50" alt={project.title} />
                      <div class="p-6 hover:bg-green-700 hover:text-white transition duration-300 ease-in">
                        <h1 class="text-2xl font-semibold mb-3">{project.title}</h1>
                      </div>
                    </button>
                  </div>
                </div>
              )
            ):(<h1 className=''>No Data found</h1>)
          }
          
          
        </div>
      </div>
    </section>
  )
}

export default Projects
