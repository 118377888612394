const AnnualReportData = [
  {
    title: "Annual Report 2022",
    date: "August 1, 2022",
    link: `${process.env.PUBLIC_URL}/resources/essentials/GNACOFA BROCHURE.pdf`,
    description: "This is the annual report of the Association for 2022.",
  }, 
  {
    title: "Annual Report 2021",
    date: "July 1, 2021",
    link: `${process.env.PUBLIC_URL}/resources/essentials/GNACOFA BROCHURE.pdf`,
    description: "This is the annual report of the Association for 2021.",
  },
  {
    title: "Annual Report 2020",
    date: "June 1, 2020",
    link: `${process.env.PUBLIC_URL}/resources/essentials/GNACOFA BROCHURE.pdf`,
    description: "This is the annual report of the Association for 2020.",
  }
]
export default AnnualReportData;