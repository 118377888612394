import React, { useEffect, useState } from 'react';
import { welcome } from '../assets';
import './pages.css';

const Contact = () => { 
  // title
  useEffect(() => {
      document.title = "Contact | Official Gancofa Administrative Portal";
  })

  // list of arrays
  const regions = ['Ashanti Region', 'Bono Region', 'Bono East Region', 'Ahafo Region', 'Western Region', 'Western North Region', 'Central Region', 'Oti Region', 'Volta Region', 'Savannah Region'];
  
  const [toggle, setToggle] = useState();
  
  function updatefunc(id) {
      setToggle(id);
  }
  return (
      <section class="bg-white">
        <div class="">
          <div class="">
            <div class="p-10 w-full bg-gradient-to-t from-white to-gray-300 flex justify-center ">
              <div className='lg:block hidden'>
                <div class="p-5 pr-1 text-green-800 border-r-2 border-green-700 w-96 text-lg italic text-center flex items-center">
                  <p>We wish to say that, You can leave us a message at the address provided to your right-hand side of this text, a message with regards to our <span class="font-bold">operations,</span> <span class="font-bold">complaint,</span>  <span class="font-bold">business proposal and enquiries.</span>  </p>
                </div>
                <div class="flex justify-center items-center">
                  <div class="border-b-2 border-red-500 w-24"></div>
                </div>
              </div>
              <div class="p-2 flex justify-center">
                <div className=''>
                  <h1 class="text-black text-4xl font-bold mb-3">Headquaters Address</h1>
                  <div class="">
                    <p class="text-sm">Ghana National Cocoa Farmers Association <br /> P. O. Box, KS 5984 <br /> Adum - Kumasi, Ghana</p>
                    <br />
                    <p><span class="font-bold">Office Line:</span> +233 (0) 32 219 4038 <br /><span class="font-bold">Field line:</span> +233 (0) 55 858 7441 <br /> <span class="font-bold">Farmers Hotline:</span>  +233 (0) 55 858 7441</p><br />
                    <h1 class="hover:text-gray-800"><i class="fa-solid fa-envelope mr-2"></i><a href="mailto:president.gancofa@outlook.com">president.gnacofa@outlook.com</a><br /><span class="ml-6"><a href="mailto:president.gancofa@outlook.com">info@gnacofa.org</a></span></h1>
                  </div>
                  <div class=" flex gap-5 lg:text-lg justify-start">
                    <a href="#"><i class="fa-brands fa-whatsapp"></i></a>
                    <a href="#"><i class="fa-brands fa-twitter"></i></a>
                    <a href="#"><i class="fa-brands fa-facebook"></i></a>
                    <a href="#"><i class="fa-brands fa-youtube"></i></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full flex justify-center">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3962.6106051963507!2d-1.6265375884278284!3d6.695055993272499!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfdb97ad29cb8e5d%3A0x71a9778386b6574!2sGhana%20National%20Cocoa%20Farmers%20Association%20(GNACOFA)!5e0!3m2!1sen!2sgh!4v1681325009219!5m2!1sen!2sgh" class="lg:w-[200svh] w-full" height="450" allowfullscreen="" loading="lay" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div class="w-full mr-1 flex justify-center">
              <div class="p-5 lg:w-1/2 shadow-sm rounded" align="center">
                <h1 class="text-2xl font-bold">Please do leave us a Feedback in the form below </h1>
                <form action="#" method="POST">
                  <div class="">
                    <div class="py-5 text-sm">
                      <input type="text" class="w-full mb-5 p-2 rounded border-2" name="name" id="name" placeholder="Enter your name here" />
                      <br />
                      <input type="email" class="w-full mb-5 text-sm border-2 p-2 rounded" name="name" id="name" placeholder="Enter your valid e-mail address here" />

                      <h1 class="text-gray-500"><span class="text-red-700">NB:</span>Please make your message precise and very concise. thank you</h1>
                      <textarea class="w-full text-sm border-2 p-2 rounded" name="name" id="name" placeholder="Enter your message here....." rows="5" cols="70"></textarea>
                    </div>
                    <button class="p-2 bg-green-600 hover:bg-green-800 text-white rounded"> Submit Feedback</button>
                  </div>
                </form>
              </div>
            </div>
            <div class="">
              <h1 class="p-4 text-center font-bold text-2xl">Regional Branch Office (Location)</h1>
              <div class="container mx-auto flex lg:justify-center p-2 gap-5 lg:px-36">
                <div class="w-full">
                  <div class="mb-2">
                      <button type='button' onClick={() => updatefunc(1)} class="content_btn">{regions[0]}<i class="fa-light fa-plus text-xl"></i></button>
                      <div className={ toggle === 1 ? 'show-content' : 'content'}>
                          <h1 class="text-black text-4xl font-bold mb-3">Headquaters Address</h1>
                          <div class="">
                              <p class="text-sm">Ghana National Cocoa Farmers Association <br /> P. O. Box, KS 5984 <br /> Adum - Kumasi, Ghana</p>
                              <br />
                              <p><span class="font-bold">Office Line:</span> +233 (0) 32 219 4038 <br /><span class="font-bold">Field line:</span> +233 (0) 55 858 7441 <br /> <span class="font-bold">Farmers Hotline:</span>  +233 (0) 55 858 7441</p><br />

                              <h1 class="hover:text-gray-800"><i class="fa-solid fa-envelope mr-2"></i><a href="mailto:president.gancofa@outlook.com">president.gnacofa@outlook.com</a><br /><span class="ml-6"><a href="mailto:president.gancofa@outlook.com">info@gnacofa.org</a></span></h1>
                          </div>
                      </div>
                  </div>
                  <div class="mb-2">
                      <button type='button' onClick={() => updatefunc(2)} class="content_btn">{regions[1]}<i class="fa-light fa-plus text-xl"></i></button>
                      <div className={toggle === 2 ? 'show-content' : 'content'}>
                          <h1 class="text-black text-4xl font-bold mb-3">Headquaters Address</h1>
                          <div class="">
                              <p class="text-sm">Ghana National Cocoa Farmers Association <br /> P. O. Box, KS 5984 <br /> Adum - Kumasi, Ghana</p>
                              <br />
                              <p><span class="font-bold">Office Line:</span> +233 (0) 32 219 4038 <br /><span class="font-bold">Field line:</span> +233 (0) 55 858 7441 <br /> <span class="font-bold">Farmers Hotline:</span>  +233 (0) 55 858 7441</p><br />

                              <h1 class="hover:text-gray-800"><i class="fa-solid fa-envelope mr-2"></i><a href="mailto:president.gancofa@outlook.com">president.gnacofa@outlook.com</a><br /><span class="ml-6"><a href="mailto:president.gancofa@outlook.com">info@gnacofa.org</a></span></h1>
                          </div>
                      </div>
                  </div>
                  <div class="mb-2">
                      <button type='button' onClick={() => updatefunc(3)} class="content_btn">{regions[2]}<i class="fa-light fa-plus text-xl"></i></button>
                      <div className={toggle === 3 ? 'show-content' : 'content'}>
                          <h1 class="text-black text-4xl font-bold mb-3">Headquaters Address</h1>
                          <div class="">
                              <p class="text-sm">Ghana National Cocoa Farmers Association <br /> P. O. Box, KS 5984 <br /> Adum - Kumasi, Ghana</p>
                              <br />
                              <p><span class="font-bold">Office Line:</span> +233 (0) 32 219 4038 <br /><span class="font-bold">Field line:</span> +233 (0) 55 858 7441 <br /> <span class="font-bold">Farmers Hotline:</span>  +233 (0) 55 858 7441</p><br />

                              <h1 class="hover:text-gray-800"><i class="fa-solid fa-envelope mr-2"></i><a href="mailto:president.gancofa@outlook.com">president.gnacofa@outlook.com</a><br /><span class="ml-6"><a href="mailto:president.gancofa@outlook.com">info@gnacofa.org</a></span></h1>
                          </div>
                      </div>
                  </div>
                  <div class="mb-2">
                      <button type='button' onClick={() => updatefunc(4)} class="content_btn">{regions[3]}<i class="fa-light fa-plus text-xl"></i></button>
                      <div className={toggle === 4 ? 'show-content' : 'content'}>
                          <h1 class="text-black text-4xl font-bold mb-3">Headquaters Address</h1>
                          <div class="">
                              <p class="text-sm">Ghana National Cocoa Farmers Association <br /> P. O. Box, KS 5984 <br /> Adum - Kumasi, Ghana</p>
                              <br />
                              <p><span class="font-bold">Office Line:</span> +233 (0) 32 219 4038 <br /><span class="font-bold">Field line:</span> +233 (0) 55 858 7441 <br /> <span class="font-bold">Farmers Hotline:</span>  +233 (0) 55 858 7441</p><br />

                              <h1 class="hover:text-gray-800"><i class="fa-solid fa-envelope mr-2"></i><a href="mailto:president.gancofa@outlook.com">president.gnacofa@outlook.com</a><br /><span class="ml-6"><a href="mailto:president.gancofa@outlook.com">info@gnacofa.org</a></span></h1>
                          </div>
                      </div>
                  </div>
                  <div class="mb-2">
                      <button type='button' onClick={() => updatefunc(5)} class="content_btn">{regions[4]}<i class="fa-light fa-plus text-xl"></i></button>
                      <div className={toggle === 5 ? 'show-content' : 'content'}>
                          <h1 class="text-black text-4xl font-bold mb-3">Headquaters Address</h1>
                          <div class="">
                              <p class="text-sm">Ghana National Cocoa Farmers Association <br /> P. O. Box, KS 5984 <br /> Adum - Kumasi, Ghana</p>
                              <br />
                              <p><span class="font-bold">Office Line:</span> +233 (0) 32 219 4038 <br /><span class="font-bold">Field line:</span> +233 (0) 55 858 7441 <br /> <span class="font-bold">Farmers Hotline:</span>  +233 (0) 55 858 7441</p><br />

                              <h1 class="hover:text-gray-800"><i class="fa-solid fa-envelope mr-2"></i><a href="mailto:president.gancofa@outlook.com">president.gnacofa@outlook.com</a><br /><span class="ml-6"><a href="mailto:president.gancofa@outlook.com">info@gnacofa.org</a></span></h1>
                          </div>
                      </div>
                  </div>
                </div>
                <div class="w-full">
                  <div class="mb-2">
                      <button type='button' onClick={() => updatefunc(6)} class="content_btn">{regions[5]}<i class="fa-light fa-plus text-xl"></i></button>
                      <div className={toggle === 6 ? 'show-content' : 'content'}>
                          <h1 class="text-black text-4xl font-bold mb-3">Headquaters Address</h1>
                          <div class="">
                              <p class="text-sm">Ghana National Cocoa Farmers Association <br /> P. O. Box, KS 5984 <br /> Adum - Kumasi, Ghana</p>
                              <br />
                              <p><span class="font-bold">Office Line:</span> +233 (0) 32 219 4038 <br /><span class="font-bold">Field line:</span> +233 (0) 55 858 7441 <br /> <span class="font-bold">Farmers Hotline:</span>  +233 (0) 55 858 7441</p><br />

                              <h1 class="hover:text-gray-800"><i class="fa-solid fa-envelope mr-2"></i><a href="mailto:president.gancofa@outlook.com">president.gnacofa@outlook.com</a><br /><span class="ml-6"><a href="mailto:president.gancofa@outlook.com">info@gnacofa.org</a></span></h1>
                          </div>
                      </div>
                  </div>
                  <div class="mb-2">
                      <button type='button' onClick={() => updatefunc(7)} class="content_btn">{regions[6]}<i class="fa-light fa-plus text-xl"></i></button>
                      <div className={toggle === 7 ? 'show-content' : 'content'}>
                          <h1 class="text-black text-4xl font-bold mb-3">Headquaters Address</h1>
                          <div class="">
                              <p class="text-sm">Ghana National Cocoa Farmers Association <br /> P. O. Box, KS 5984 <br /> Adum - Kumasi, Ghana</p>
                              <br />
                              <p><span class="font-bold">Office Line:</span> +233 (0) 32 219 4038 <br /><span class="font-bold">Field line:</span> +233 (0) 55 858 7441 <br /> <span class="font-bold">Farmers Hotline:</span>  +233 (0) 55 858 7441</p><br />

                              <h1 class="hover:text-gray-800"><i class="fa-solid fa-envelope mr-2"></i><a href="mailto:president.gancofa@outlook.com">president.gnacofa@outlook.com</a><br /><span class="ml-6"><a href="mailto:president.gancofa@outlook.com">info@gnacofa.org</a></span></h1>
                          </div>
                      </div>
                  </div>
                  <div class="mb-2">
                      <button type='button' onClick={() => updatefunc(8)} class="content_btn">{regions[7]}<i class="fa-light fa-plus text-xl"></i></button>
                      <div className={toggle === 8 ? 'show-content' : 'content'}>
                          <h1 class="text-black text-4xl font-bold mb-3">Headquaters Address</h1>
                          <div class="">
                              <p class="text-sm">Ghana National Cocoa Farmers Association <br /> P. O. Box, KS 5984 <br /> Adum - Kumasi, Ghana</p>
                              <br />
                              <p><span class="font-bold">Office Line:</span> +233 (0) 32 219 4038 <br /><span class="font-bold">Field line:</span> +233 (0) 55 858 7441 <br /> <span class="font-bold">Farmers Hotline:</span>  +233 (0) 55 858 7441</p><br />

                              <h1 class="hover:text-gray-800"><i class="fa-solid fa-envelope mr-2"></i><a href="mailto:president.gancofa@outlook.com">president.gnacofa@outlook.com</a><br /><span class="ml-6"><a href="mailto:president.gancofa@outlook.com">info@gnacofa.org</a></span></h1>
                          </div>
                      </div>
                  </div>
                  <div class="mb-2">
                      <button type='button' onClick={() => updatefunc(9)} class="content_btn">{regions[8]}<i class="fa-light fa-plus text-xl"></i></button>
                      <div className={toggle === 9 ? 'show-content' : 'content'}>
                          <h1 class="text-black text-4xl font-bold mb-3">Headquaters Address</h1>
                          <div class="">
                              <p class="text-sm">Ghana National Cocoa Farmers Association <br /> P. O. Box, KS 5984 <br /> Adum - Kumasi, Ghana</p>
                              <br />
                              <p><span class="font-bold">Office Line:</span> +233 (0) 32 219 4038 <br /><span class="font-bold">Field line:</span> +233 (0) 55 858 7441 <br /> <span class="font-bold">Farmers Hotline:</span>  +233 (0) 55 858 7441</p><br />

                              <h1 class="hover:text-gray-800"><i class="fa-solid fa-envelope mr-2"></i><a href="mailto:president.gancofa@outlook.com">president.gnacofa@outlook.com</a><br /><span class="ml-6"><a href="mailto:president.gancofa@outlook.com">info@gnacofa.org</a></span></h1>
                          </div>
                      </div>
                  </div>
                  <div class="mb-2">
                      <button type='button' onClick={() => updatefunc(10)} class="content_btn">{regions[9]}<i class="fa-light fa-plus text-xl"></i></button>
                      <div className={toggle === 10 ? 'show-content' : 'content'}>
                          <h1 class="text-black text-4xl font-bold mb-3">Headquaters Address</h1>
                          <div class="">
                              <p class="text-sm">Ghana National Cocoa Farmers Association <br /> P. O. Box, KS 5984 <br /> Adum - Kumasi, Ghana</p>
                              <br />
                              <p><span class="font-bold">Office Line:</span> +233 (0) 32 219 4038 <br /><span class="font-bold">Field line:</span> +233 (0) 55 858 7441 <br /> <span class="font-bold">Farmers Hotline:</span>  +233 (0) 55 858 7441</p><br />

                              <h1 class="hover:text-gray-800"><i class="fa-solid fa-envelope mr-2"></i><a href="mailto:president.gancofa@outlook.com">president.gnacofa@outlook.com</a><br /><span class="ml-6"><a href="mailto:president.gancofa@outlook.com">info@gnacofa.org</a></span></h1>
                          </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}
export default Contact
