import React, { useEffect } from 'react'
import welcome from '../assets/images/welcome.jpg'
import { adb, bkagro, cbod, ecom, gfll, kbaduagro, ssnit, pat } from '../assets'

const Partners = () => { 
  useEffect(() => {
    document.title = "Partners";
  })
  return (
    <section class="">
      <div class="lg:h-96 overflow-hidden relative">
        <img src={welcome} class="block w-full  brightness-50" width="10%" alt="" />
        <div class="px-5 absolute top-[100px] lg:top-20 left-[5px] lg:left-64">
          <h1 class="text-3xl font-bold text-green-600 shadow-md font-['Arial'] w-full uppercase">Partners</h1>
        </div>
      </div>
      <div class="container mx-auto lg:px-44">
        <div class="p-3">
          <h1 class="lg:text-xl text-md"><span class="uppercase">GNACOFA</span> has opened it's doors and windows to partner with private and public companies, local and international NGO's, donor funding agencies, and investment companies, stake holders in the cocoa industry such as cocoa buying companies to implement sustainable cocoa farming programs that will help improve the livelihood of our members and all cocoa farmers in Ghana.</h1>
          <div class="">
            <div class="">
              <section class="overflow-hidden text-neutral-700">
                <div class="px-5 py-2 lg:px-32 lg:pt-12">
                  <div class="-m-1 flex flex-wrap md:-m-2">
                    <div class="flex w-1/3 flex-wrap">
                      <div class="w-full p-1 md:p-2">
                          <img alt="gallery" class="block h-full w-full rounded-lg object-cover object-center" width="1%" src={adb} />
                      </div>
                    </div>
                    <div class="flex w-1/3 flex-wrap">
                      <div class="w-full p-1 md:p-2">
                          <img alt="gallery" class="block h-full w-full rounded-lg object-cover object-center" src={bkagro} />
                      </div>
                    </div>
                    <div class="flex w-1/3 flex-wrap">
                      <div class="w-full p-1 md:p-2">
                          <img alt="gallery" class="block h-full w-full rounded-lg object-cover object-center" src={cbod} />
                      </div>
                    </div>
                    <div class="flex w-1/3 flex-wrap">
                      <div class="w-full p-1 md:p-2">
                          <img alt="gallery" class="block h-full w-full rounded-lg object-cover object-center" src={ecom} />
                      </div>
                    </div>
                    <div class="flex w-1/3 flex-wrap">
                      <div class="w-full p-1 md:p-2">
                          <img alt="gallery" class="block h-full w-full rounded-lg object-cover object-center" src={gfll} />
                      </div>
                    </div>
                    <div class="flex w-1/3 flex-wrap">
                      <div class="w-full p-1 md:p-2">
                          <img alt="gallery" class="block h-full w-full rounded-lg object-cover object-center" src={kbaduagro} />
                      </div>
                    </div>
                    <div class="flex w-1/3 flex-wrap">
                      <div class="w-full p-1 md:p-2">
                          <img alt="gallery" class="block h-full w-full rounded-lg object-cover object-center" src={ssnit} />
                      </div>
                    </div>
                    <div class="flex w-1/3 flex-wrap">
                      <div class="w-full p-1 md:p-2">
                          <img alt="gallery" class="block h-full w-full rounded-lg object-cover object-center" src={pat} />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <div class="text-center mt-5">
            <h1 className='lg:text-xl'>GNACOFA is open to the public for partnership. <br/> if organizations, and individuals are interested in partnering with us, please message us at <a href="mailto:partner@gnacofa.org" class="hover:text-green-800 text-green-500 lowercase">partner@gnacofa.org</a> or contact the office hotline on +233 (0) 32 219 4038</h1>
          </div>
        </div>
      </div>
    </section>
  )
}
export default Partners
