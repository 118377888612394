import React from 'react'
import { useParams } from 'react-router-dom';
import News from '../../constants/News';

export default function NewsDetails() {
  const { title } = useParams();
  const news = News.find((news) => news.title === decodeURIComponent(title));

  if (!news) {
    return <div>News not found</div>;
  }
  return (
    <div className={`container mx-auto lg:py-5 lg:px-32`}>
      <div className={`p-40 overflow-y-scroll`}>
        <div className={`flex justify-between items-center border-b p-2`}>
          <div className={``}>
            <h1 className={`text-2xl font-bold`}>{news.title}</h1>
          </div>
          <h4 className={`text-sm text-gray-400`}>Posted: {news.date_created}</h4>
        </div>
        <div className={`p-2`}>
          <p className={`text-lg`}>{news.content}</p>
          <h6 className={``}>Date: {news.date_created}</h6>
          <h6 className={``}>Location: {news.author}</h6>
        </div>
      </div>
    </div>
  )
}
