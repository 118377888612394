import React, { useEffect, useState } from 'react'
import welcome_img from '../assets/images/grp.jpg'

export default function Faq() {
  useEffect(() => {
    document.title = "Frequently Asked Questions"
  })
  const [showContent, setShowContent] = useState(1);
  return (
    <section class="bg-gray-100">
      <div class="lg:container lg:mx-auto lg:px-36">
        <h1 class="font-bold text-4xl text-center p-5">Any Questions? Look Here</h1>
        <div className='lg:flex flex-wrap gap-2'>
          <div className='lg:flex flex lg:flex-col divide-x overflow-x-scroll lg:w-[25vh]'>
            {buttomtabs(showContent, setShowContent, 1, 'Who is GNACOFA?')}
            {buttomtabs(showContent, setShowContent, 2, 'Why should your be part of GNACOFA?')}
            {buttomtabs(showContent, setShowContent, 3, 'Benefits of becoming part of GNACOFA?')}
            {buttomtabs(showContent, setShowContent, 4, 'Requiremnets to consider/know to become part of GNACOFA')}
            {buttomtabs(showContent, setShowContent, 5, 'GNACOFA Membership info')}
            {buttomtabs(showContent, setShowContent, 6, 'How to apply')}
          </div>
          <div className='lg:w-[60vh] p-5 lg:h-[61svh] overflow-y-scroll'>
            {showContent === 1 &&
              <div class="">
                <h1 class="font-bold text-xl">Want To Become Part Of GNACOFA?</h1>
                <p className='text-lg text-justify'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque natus aut ea delectus tempore! Fugit quia autem voluptatem odit ipsam repellat atque veniam soluta at, temporibus adipisci obcaecati. Sapiente, perspiciatis! Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sapiente, quaerat culpa. Porro odit praesentium adipisci fuga incidunt, quidem, quo repellat facere autem nam tenetur mollitia reprehenderit sed quos ipsa sequi! lorem Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptas nostrum itaque accusamus dolores, suscipit voluptate delectus aspernatur iure beatae earum officia maxime molestias vitae ex maiores optio, veniam tempora atque? Lorem ipsum dolor sit amet, consectetur adipisicing elit. Enim quas culpa natus autem est architecto tenetur alias! Libero quod, minus omnis accusamus adipisci laborum ut deleniti explicabo nihil commodi totam! Lorem, ipsum dolor sit amet consectetur adipisicing elit. Non, obcaecati eveniet. Molestiae voluptatibus, minus quasi, eius assumenda obcaecati nulla neque, eum vel debitis laborum illum ratione odit repellendus placeat similique. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos, minima est! Suscipit ea ipsa deleniti error aspernatur, odit reprehenderit omnis, magni mollitia blanditiis iusto? Dolorem assumenda ipsum debitis nostrum necessitatibus.</p>
              </div>
            }
            {showContent === 2 &&
              <div class="">
                <h1 class="font-bold text-xl">Why should your be part of GNACOFA?</h1>
                <p className='text-lg text-justify'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque natus aut ea delectus tempore! Fugit quia autem voluptatem odit ipsam repellat atque veniam soluta at, temporibus adipisci obcaecati. Sapiente, perspiciatis! Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sapiente, quaerat culpa. Porro odit praesentium adipisci fuga incidunt, quidem, quo repellat facere autem nam tenetur mollitia reprehenderit sed quos ipsa sequi! lorem Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptas nostrum itaque accusamus dolores, suscipit voluptate delectus aspernatur iure beatae earum officia maxime molestias vitae ex maiores optio, veniam tempora atque? Lorem ipsum dolor sit amet, consectetur adipisicing elit. Enim quas culpa natus autem est architecto tenetur alias! Libero quod, minus omnis accusamus adipisci laborum ut deleniti explicabo nihil commodi totam! Lorem, ipsum dolor sit amet consectetur adipisicing elit. Non, obcaecati eveniet. Molestiae voluptatibus, minus quasi, eius assumenda obcaecati nulla neque, eum vel debitis laborum illum ratione odit repellendus placeat similique. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos, minima est! Suscipit ea ipsa deleniti error aspernatur, odit reprehenderit omnis, magni mollitia blanditiis iusto? Dolorem assumenda ipsum debitis nostrum necessitatibus.</p>
              </div>
            }
            {showContent === 3 &&
              <div class="">
                <h1 class="font-bold text-xl">Benefits of becoming part of GNACOFA?</h1>
                <p className='text-lg text-justify'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque natus aut ea delectus tempore! Fugit quia autem voluptatem odit ipsam repellat atque veniam soluta at, temporibus adipisci obcaecati. Sapiente, perspiciatis! Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sapiente, quaerat culpa. Porro odit praesentium adipisci fuga incidunt, quidem, quo repellat facere autem nam tenetur mollitia reprehenderit sed quos ipsa sequi! lorem Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptas nostrum itaque accusamus dolores, suscipit voluptate delectus aspernatur iure beatae earum officia maxime molestias vitae ex maiores optio, veniam tempora atque? Lorem ipsum dolor sit amet, consectetur adipisicing elit. Enim quas culpa natus autem est architecto tenetur alias! Libero quod, minus omnis accusamus adipisci laborum ut deleniti explicabo nihil commodi totam! Lorem, ipsum dolor sit amet consectetur adipisicing elit. Non, obcaecati eveniet. Molestiae voluptatibus, minus quasi, eius assumenda obcaecati nulla neque, eum vel debitis laborum illum ratione odit repellendus placeat similique. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos, minima est! Suscipit ea ipsa deleniti error aspernatur, odit reprehenderit omnis, magni mollitia blanditiis iusto? Dolorem assumenda ipsum debitis nostrum necessitatibus.</p>
              </div>
            }
            {showContent === 4 &&
              <div class="">
                <h1 class="font-bold text-xl">Requiremnets to consider/know to become part of GNACOFA</h1>
                <p className='text-lg text-justify'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque natus aut ea delectus tempore! Fugit quia autem voluptatem odit ipsam repellat atque veniam soluta at, temporibus adipisci obcaecati. Sapiente, perspiciatis! Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sapiente, quaerat culpa. Porro odit praesentium adipisci fuga incidunt, quidem, quo repellat facere autem nam tenetur mollitia reprehenderit sed quos ipsa sequi! lorem Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptas nostrum itaque accusamus dolores, suscipit voluptate delectus aspernatur iure beatae earum officia maxime molestias vitae ex maiores optio, veniam tempora atque? Lorem ipsum dolor sit amet, consectetur adipisicing elit. Enim quas culpa natus autem est architecto tenetur alias! Libero quod, minus omnis accusamus adipisci laborum ut deleniti explicabo nihil commodi totam! Lorem, ipsum dolor sit amet consectetur adipisicing elit. Non, obcaecati eveniet. Molestiae voluptatibus, minus quasi, eius assumenda obcaecati nulla neque, eum vel debitis laborum illum ratione odit repellendus placeat similique. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos, minima est! Suscipit ea ipsa deleniti error aspernatur, odit reprehenderit omnis, magni mollitia blanditiis iusto? Dolorem assumenda ipsum debitis nostrum necessitatibus.</p>
              </div>
            }
            {showContent === 5 &&
              <div class="">
                <h1 class="font-bold text-xl">GNACOFA Membership info</h1>
                <p className='text-lg text-justify'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque natus aut ea delectus tempore! Fugit quia autem voluptatem odit ipsam repellat atque veniam soluta at, temporibus adipisci obcaecati. Sapiente, perspiciatis! Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sapiente, quaerat culpa. Porro odit praesentium adipisci fuga incidunt, quidem, quo repellat facere autem nam tenetur mollitia reprehenderit sed quos ipsa sequi! lorem Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptas nostrum itaque accusamus dolores, suscipit voluptate delectus aspernatur iure beatae earum officia maxime molestias vitae ex maiores optio, veniam tempora atque? Lorem ipsum dolor sit amet, consectetur adipisicing elit. Enim quas culpa natus autem est architecto tenetur alias! Libero quod, minus omnis accusamus adipisci laborum ut deleniti explicabo nihil commodi totam! Lorem, ipsum dolor sit amet consectetur adipisicing elit. Non, obcaecati eveniet. Molestiae voluptatibus, minus quasi, eius assumenda obcaecati nulla neque, eum vel debitis laborum illum ratione odit repellendus placeat similique. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos, minima est! Suscipit ea ipsa deleniti error aspernatur, odit reprehenderit omnis, magni mollitia blanditiis iusto? Dolorem assumenda ipsum debitis nostrum necessitatibus.</p>
              </div>
            }
            {showContent === 6 &&
              <div class="">
                <h1 class="font-bold text-xl">How to apply?</h1>
                <p className='text-lg text-justify'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque natus aut ea delectus tempore! Fugit quia autem voluptatem odit ipsam repellat atque veniam soluta at, temporibus adipisci obcaecati. Sapiente, perspiciatis! Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sapiente, quaerat culpa. Porro odit praesentium adipisci fuga incidunt, quidem, quo repellat facere autem nam tenetur mollitia reprehenderit sed quos ipsa sequi! lorem Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptas nostrum itaque accusamus dolores, suscipit voluptate delectus aspernatur iure beatae earum officia maxime molestias vitae ex maiores optio, veniam tempora atque? Lorem ipsum dolor sit amet, consectetur adipisicing elit. Enim quas culpa natus autem est architecto tenetur alias! Libero quod, minus omnis accusamus adipisci laborum ut deleniti explicabo nihil commodi totam! Lorem, ipsum dolor sit amet consectetur adipisicing elit. Non, obcaecati eveniet. Molestiae voluptatibus, minus quasi, eius assumenda obcaecati nulla neque, eum vel debitis laborum illum ratione odit repellendus placeat similique. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos, minima est! Suscipit ea ipsa deleniti error aspernatur, odit reprehenderit omnis, magni mollitia blanditiis iusto? Dolorem assumenda ipsum debitis nostrum necessitatibus.</p>
              </div>
            }
          </div>
        </div> 
      </div>
    </section>
  )
}

const buttomtabs = (showContent, setShowContent, id, title) => {
  return (
    <button className={`text-green-600  w-full text-start  ${showContent === id && 'lg:border-l-4 font-bold  lg:border-red-800 text-red-800'} p-2`} onClick={() => { setShowContent(id) }}> {title} </button>
  )
} 