import React from 'react'
import { welcome_img } from "../../assets";
import { Link, useNavigate } from 'react-router-dom';

const Carousel = () => {
  const navigate = useNavigate();
  return (
    <section class="relative">
      <div className='relative lg:h-[600px] overflow-hidden'>
        <img src={welcome_img} className='object-fill lg:object-cover brightness-50' alt="group" />
        <div className='absolute inset-0 flex justify-center items-center  lg:px-44'>
          <div className='space-y-1'>
            <div className='flex flex-wrap justify-center w-full'>
              <div className='space-y-1 lg:space-y-5 text-xs text-center text-white w-full font-bold'>
                <h1 className='text-xl lg:text-4xl'>Welcome to </h1>
                <h1 className='text-xl lg:text-6xl '>Ghana National Cocoa Farmers Association</h1>
                {/* <div className='bg-white w-full lg:h-[1px] rounded-md'></div> */}
              </div>
            </div>
            <div className=' lg:block md:block  p-2  text-center bg-opacity-20 text-green-500'><p className='italic font-bold lg:text-xl lg:hidden'>This is the Official website of the Ghana National Cocoa Farmers Association</p><p className='italic font-bold lg:text-xl hidden lg:block'>This Is The Offical Web Page Of The Ghana National Cocoa Farmers Association (GNACOFA). This Is A Photo Of The Association Taking After A General Meeting Held At The Kumasi Cultural Center Which Was Graced By The Tepaman Hene Of Tepa Stool Land And Other Bigger Dignitories In The Country And As Well As Accros The World.</p></div>
            <div className='flex justify-center lg:justify-center items-center'>
              <div className='p-1 rounded-full text-white  bg-green-700 hover:bg-green-900 lg:mt-5 cursor-pointer lg:w-[220px] w-[120px] flex justify-center'>
                <buttom onClick={() => { navigate('/about/about-us')}} className='flex  items-center gap-x-3 lg:text-lg text-xs' >Learn More <span class="material-symbols-outlined">
                  arrow_forward
                </span></buttom>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-green-700">
        <div class="py-3 flex text-sm items-center divide-x-2 divide-white">
          <div class="text-center w-44">
            <h1 class="text-yellow-400 font-bold text-xs">Latest News</h1>
          </div>
          <div className={`flex justify-center items-center px-5`}>
            <div className='flex items-center gap-x-3'>
              <h1 className='text-white'>||</h1>
              <a href="/new/:id" className='hover:text-green-500'>
                <p class="text-white">Goverment of Ghana under the leadership of H.E. Nana Addo Dankwa Akuffo Addo, on saturday 9th September, 2023, announces increased the price of Cocoa per bag to GH¢1,308 at an event in Tepa | <small>Posted: 24-5-2024</small></p>
              </a>
              <h1 className='text-white'>||</h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default Carousel
