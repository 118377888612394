import React from 'react';
import './index.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Navbar, Topbar, Footer } from './components';
import { Home, About, Management, Structure, Statistics, Partners, Contact, FAQ, AnnualReport, Newsletters, PressRelease, FormsPage, NewsPage, EventsPage, MediaGallery, MediaVideo, EventDetails, NewsDetails, ProjectDetails} from './pages';


const App = () => {
  return (
    <BrowserRouter>
      <div className="App relative">
        <Topbar />
        <Navbar />
        <div className="web__content">
          <Routes>
            <Route index element={<Home />} />
            <Route path='/about/about-us' element={<About />} />
            <Route path='/about/our-management' element={<Management />} />
            <Route path='/about/our-structure' element={<Structure />} />
            <Route path='/statistics' element={<Statistics />} />
            <Route path='/partners' element={<Partners />} />
            <Route path='/resources/forms' element={<FormsPage />} />
            <Route path='/resources/annual-reports' element={<AnnualReport />} />
            <Route path='/resources/newsletters' element={<Newsletters />} />
            <Route path='/resources/press-release' element={<PressRelease />} />
            <Route path='/media/news' element={<NewsPage />} />
            <Route path='/media/events' element={<EventsPage />} />
            <Route path='/media/gallery' element={<MediaGallery />} />
            <Route path='/media/video' element={<MediaVideo />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/faq' element={<FAQ />} />
            <Route path='/event/detail/:title' element={<EventDetails />} />
            <Route path='/news/detail/:title' element={<NewsDetails />} />
            <Route path='/project/detail/:title' element={<ProjectDetails />} />
          </Routes>
        </div>
        <Footer/>
      </div>
    </BrowserRouter>
  )
}
export default App
