const PressReleaseData = [
  {
    title: "Press Release: GNACOFA Announces New Membership",
    date: "July 15, 2022",
    link: `${process.env.PUBLIC_URL}/resources/essentials/GNACOFA BROCHURE.pdf`,
    description: "This is a press release announcing the new membership of GNACOFA.",
  },
  {
    title: "Press Release: GNACOFA Announces New Co-operative Credit Union",
    date: "July 10, 2022",
    link: `${process.env.PUBLIC_URL}/resources/essentials/GNACOFA BROCHURE.pdf`,
    description: "This is a press release announcing the new co-operative credit union.",
  },
  {
    title: "Press Release: GNACOFA Announces New Farmers' Co-op Program",
    date: "July 5, 2022",
    link: `${process.env.PUBLIC_URL}/resources/essentials/GNACOFA BROCHURE.pdf`,
    description: "This is a press release announcing the new farmers' co-op program.",
  }
]
export default PressReleaseData;