import React from 'react'
import { adb, bkagro, cbod, ecom, gfll, kbaduagro, ssnit, pat } from '../../assets'

const Partner = () => {
  return (
    <section class="bg-white">
      <div class="container mx-auto lg:px-32 py-5">
        <div class="text-center py-4">
          <h1 class="text-4xl md:text-4xl text-green-700 font-semibold font-['Gugi'] uppercase">PARTNERS & SPONSORS</h1>
          <div className='flex justify-center items-end'>
            <div className='w-[100px] h-[3px] rounded-lg bg-yellow-400 '></div>
          </div>
        </div>
        <div class="">
          <div class="flex flex-wrap lg:flex-row justify-center items-center gap-3 lg:gap-5 p-5">
            <img src={adb} alt="logo" className="w-28 h-20" />
            <img src={bkagro} alt="logo" className="w-28 h-20" />
            <img src={cbod} alt="logo" className="w-28 h-20" />
            <img src={ecom} alt="logo" className="w-28 h-20" />
            <img src={gfll} alt="logo" className="w-28 h-20" />
            <img src={kbaduagro} alt="logo" className="w-28 h-20" />
            <img src={ssnit} alt="logo" className="w-28 h-20" />
            <img src={pat} alt="logo" className="w-28 h-16" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Partner
