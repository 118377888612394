import {React, useEffect, useState} from 'react'
import welcome from '../../../assets/images/welcome.jpg'
import { adugyansah, barnie, boa, defaultimg, director, g3, g4, kay, mulari, newton, ophilia, rev, ackah, tahiru } from '../../../assets'
import './management.css'


const Management = () => { 
  useEffect(() => {
    document.title = "Our Management"
  })
  const [showContent, setShowContent] = useState(1);
  return (
    <section class="">
      <div class="container mx-auto lg:px-36 lg:p-10">
        <h1 class="text-3xl font-bold text-green-600 shadow-md font-['Arial'] w-full uppercase mb-4">Our Management</h1>
        <div className='lg:flex  gap-2'>
          <div className='lg:flex flex lg:flex-col divide-x overflow-x-scroll'>
            {buttomtabs(showContent, setShowContent, 1, 'Directors')}
            {buttomtabs(showContent, setShowContent, 2, 'Executive Council')}
            {buttomtabs(showContent, setShowContent, 3, 'Operating Board')}
            {buttomtabs(showContent, setShowContent, 4, 'Technical Expertise')}
            {buttomtabs(showContent, setShowContent, 5, 'International Reps')}
            {buttomtabs(showContent, setShowContent, 6, 'Advisory Committee')}
          </div>
          <div className='lg:h-[61svh] overflow-y-scroll'>
            {showContent === 1 &&
              <div class={``}>
                <h1 class="text-red-800 font-['Tahoma'] font-bold uppercase text-center text-xl p-3 ">Directors (6 Member)</h1>
                <div className='flex justify-center items-end'>
                  <div className='w-[100px] h-[3px] rounded-lg bg-yellow-400 '></div>
                </div>
                <div class="p-3 flex flex-wrap md:flex-row lg:flex-row justify-center items-center gap-10">
                  <div class="image_container">
                    <img class="w-full" src={director} alt="Sunset in the mountains" />
                    <div class="px-6 py-4 text-center">
                      <div class="font-bold text-xl mb-2">Stephenson Anane Boateng</div>
                      <p class="text-red-700 uppercase text-base">GNACOFA National President</p>
                    </div>
                  </div>
                  <div class="image_container">
                    <img class="w-full" src={boa} alt="Sunset in the mountains" />
                    <div class="px-6 py-4 text-center">
                      <div class="font-bold text-xl mb-2">Michael Yeboah</div>
                      <p class="text-red-700 uppercase text-base">GNACOFA Vice President</p>
                    </div>
                  </div>
                  <div class="image_container">
                    <img class="w-full" src={g3} alt="Sunset in the mountains" />
                    <div class="px-6 py-4 text-center">
                      <div class="font-bold text-xl mb-2">Caleb Adu Gyamfi Boateng</div>
                      <p class="text-red-700 uppercase text-base">Administrator</p>
                    </div>
                  </div>
                  <div class="image_container">
                    <img class="w-full" src={ophilia} alt="Sunset in the mountains" />
                    <div class="px-6 py-4 text-center">
                      <div class="font-bold text-xl mb-2">Ophilia Tumasi</div>
                      <p class="text-red-700 uppercase text-base">Treasurer</p>
                    </div>
                  </div>
                  <div class="image_container">
                    <img class="w-full" src={g4} alt="Sunset in the mountains" />
                    <div class="px-6 py-4 text-center">
                      <div class="font-bold text-xl mb-2">Newell Sarpong Boateng</div>
                      <p class="text-red-700 uppercase text-base">Director</p>
                    </div>
                  </div>
                  <div class="image_container">
                    <img class="w-full" src={barnie} alt="Sunset in the mountains" />
                    <div class="px-6 py-4 text-center">
                      <div class="font-bold text-xl mb-2">Samuel Kwabena Barnie</div>
                      <p class="text-red-700 uppercase text-base">Director</p>
                    </div>
                  </div>
                </div>
              </div>
            }
            {showContent === 2 &&
              <div class={``}>
                <h1 class="text-red-900 font-['Tahoma'] font-bold uppercase text-center text-xl p-3">Executive Council (Corperate Body) - 15 Member</h1>
                <div className='flex justify-center items-end'>
                  <div className='w-[100px] h-[3px] rounded-lg bg-yellow-400 '></div>
                </div>
                <div class="flex flex-wrap md:flex-row lg:flex-row justify-center items-center gap-10">
                  <div class="image_container">
                    <img class="w-full" src={director} alt="Sunset in the mountains" />
                    <div class="px-6 py-4 text-center">
                      <div class="font-bold text-xl mb-2">Stephenson Anane Boateng</div>
                      <p class="text-red-700 uppercase text-base">National President</p>
                    </div>
                  </div>
                  <div class="image_container">
                    <img class="w-full" src={boa} alt="Sunset in the mountains" />
                    <div class="px-6 py-4 text-center">
                      <div class="font-bold text-xl mb-2">Michael Yeboah Boateng</div>
                      <p class="text-red-700 uppercase text-base">Vice President</p>
                    </div>
                  </div>
                  <div class="image_container">
                    <img class="w-full" src={adugyansah} alt="Sunset in the mountains" />
                    <div class="px-6 py-4 text-center">
                      <div class="font-bold text-xl mb-2">John Abugyansah</div>
                      <p class="text-red-700 uppercase text-base">General Secretary</p>
                    </div>
                  </div>
                  <div class="image_container">
                    <img class="w-full" src={defaultimg} alt="Sunset in the mountains" />
                    <div class="px-6 py-4 text-center">
                      <div class="font-bold text-xl mb-2">Isaac Kwabena Dawuni</div>
                      <p class="text-red-700 uppercase text-base"></p>
                    </div>
                  </div>
                  <div class="image_container">
                    <img class="w-full" src={rev} alt="Sunset in the mountains" />
                    <div class="px-6 py-4 text-center">
                      <div class="font-bold text-xl mb-2">F.B.C Adjei</div>
                      <p class="text-red-700 uppercase text-base">Financial Controller</p>
                    </div>
                  </div>
                  <div class="image_container">
                    <img class="w-full" src={ophilia} alt="" />
                    <div class="px-6 py-4 text-center">
                      <div class="font-bold text-xl mb-2">Ophilia Twumasi</div>
                      <p class="text-red-700 uppercase text-base">Treasurer</p>
                    </div>
                  </div>
                  <div class="image_container">
                    <img class="w-full" src={g4} alt="Sunset in the mountains" />
                    <div class="px-6 py-4 text-center">
                      <div class="font-bold text-xl mb-2">Newell Sarpong Boateng</div>
                      <p class="text-red-700 uppercase text-base">Head of Co-ordinator</p>
                    </div>
                  </div>
                  <div class="image_container">
                    <img class="w-full" src={ackah} alt="Sunset in the mountains" />
                    <div class="px-6 py-4 text-center">
                      <div class="font-bold text-xl mb-2">Augustine Ackah</div>
                      <p class="text-red-700 uppercase text-base">Secretary to the Western Chapter</p>
                    </div>
                  </div>
                  <div class="image_container">
                    <img class="w-full" src={newton} alt="Sunset in the mountains" />
                    <div class="px-6 py-4 text-center">
                      <div class="font-bold text-xl mb-2">Ellis Kelly Newton</div>
                      <p class="text-red-700 uppercase text-base">Fund Solicitor / International Rep. </p>
                    </div>
                  </div>
                  <div class="image_container">
                    <img class="w-full" src={mulari} alt="Sunset in the mountains" />
                    <div class="px-6 py-4 text-center">
                      <div class="font-bold text-xl mb-2">Mulani Bhagwan Gangadhar</div>
                      <p class="text-red-700 uppercase text-base">Sales / Marketer</p>
                    </div>
                  </div>
                  <div class="image_container">
                    <img class="w-full" src={defaultimg} alt="Sunset in the mountains" />
                    <div class="px-6 py-4 text-center">
                      <div class="font-bold text-xl mb-2">Emmanuel Asiedu Boateng</div>
                      <p class="text-red-700 uppercase text-base">General Organizer</p>
                    </div>
                  </div>
                  <div class="image_container">
                    <img class="w-full" src={defaultimg} alt="Sunset in the mountains" />
                    <div class="px-6 py-4 text-center">
                      <div class="font-bold text-xl mb-2">Ama Osaah</div>
                      <p class="text-red-700 uppercase text-base">Head of Women in Cocoa Farming</p>
                    </div>
                  </div>
                  <div class="image_container">
                    <img class="w-full" src={g3} alt="Sunset in the mountains" />
                    <div class="px-6 py-4 text-center">
                      <div class="font-bold text-xl mb-2">Caleb Adu Gyamfi Boateng</div>
                      <p class="text-red-700 uppercase text-base">Head of Youth in cocoa Farming</p>
                    </div>
                  </div>
                  <div class="image_container">
                    <img class="w-full" src={barnie} alt="" />
                    <div class="px-6 py-4 text-center">
                      <div class="font-bold text-xl mb-2">Samuel Kwabena Barnie</div>
                      <p class="text-red-700 uppercase text-base">Member</p>
                    </div>
                  </div>
                  <div class="image_container">
                    <img class="w-full" src={defaultimg} alt="" />
                    <div class="px-6 py-4 text-center">
                      <div class="font-bold text-xl mb-2">Samuel Kwaku Mensah</div>
                      <p class="text-red-700 uppercase text-base">Member</p>
                    </div>
                  </div>
                  <div class="image_container">
                    <img class="w-full" src={defaultimg} alt="" />
                    <div class="px-6 py-4 text-center">
                      <div class="font-bold text-xl mb-2">Solomon Osei Ababio</div>
                      <p class="text-red-700 uppercase text-base">Member</p>
                    </div>
                  </div>
                </div>
              </div>
            }
            {showContent === 3 &&
              <div class={``}>
                <h1 class="text-red-900 font-['Tahoma'] font-bold uppercase text-center text-xl p-3 ">OPERATING BOARD OF Directors (5 Member)</h1>
                <div className='flex justify-center items-end'>
                  <div className='w-[100px] h-[3px] rounded-lg bg-yellow-400 '></div>
                </div>
                <div class="pt-5 flex flex-wrap md:flex-row lg:flex-row justify-center items-center gap-10">
                  <div class="w-80 rounded overflow-hidden shadow-lg transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300 relative">
                    <img class="w-full" src={boa} alt="" />
                    <div class="px-6 py-4 text-center">
                      <div class="font-bold text-xl mb-2">Michael Yeboah Boateng</div>
                      <p class="text-red-700 uppercase text-base">Vice President</p>
                    </div>
                  </div>
                  <div class="w-80 rounded overflow-hidden shadow-lg transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300 relative">
                    <img class="w-full" src={adugyansah} alt="" />
                    <div class="px-6 py-4 text-center">
                      <div class="font-bold text-xl mb-2">John Abugyansah</div>
                      <p class="text-red-700 uppercase text-base">General Secretary</p>
                    </div>
                  </div>
                  <div class="w-80 rounded overflow-hidden shadow-lg transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300 relative">
                    <img class="w-full" src={rev} alt="" />
                    <div class="px-6 py-4 text-center">
                      <div class="font-bold text-xl mb-2">F.B.C Adjei</div>
                      <p class="text-red-700 uppercase text-base">Financial Controller</p>
                    </div>
                  </div>
                  <div class="w-80 rounded overflow-hidden shadow-lg transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300 relative">
                    <img class="w-full" src={defaultimg} alt="" />
                    <div class="px-6 py-4 text-center">
                      <div class="font-bold text-xl mb-2">Ophilia Twumasi</div>
                      <p class="text-red-700 uppercase text-base">Treasurer</p>
                    </div>
                  </div>
                  <div class="w-80 rounded overflow-hidden shadow-lg transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300 relative">
                    <img class="w-full" src={g3} alt="" />
                    <div class="px-6 py-4 text-center">
                      <div class="font-bold text-xl mb-2">Caleb Adugyamfi Boateng</div>
                      <p class="text-red-700 uppercase text-base">Administrator / Orgainzer</p>
                    </div>
                  </div>
                </div>
              </div>
            }
            {showContent === 4 &&
              <div class={``}>
                <h1 class="text-red-900 font-['Tahoma'] font-bold uppercase text-center text-xl p-3 ">Technical Expertise </h1>
                <div className='flex justify-center items-end'>
                  <div className='w-[100px] h-[3px] rounded-lg bg-yellow-400 '></div>
                </div>
                <div class="pt-5 flex flex-wrap md:flex-row lg:flex-row justify-center items-center gap-10">
                  <div class="w-80 rounded overflow-hidden shadow-lg transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300 relative">
                    <img class="w-full" src={tahiru} alt="" />
                    <div class="px-6 py-4 text-center">
                      <div class="font-bold text-xl mb-2">Tahiru Musah</div>
                      <p class="text-red-700 uppercase text-base">Technical Advisor</p>
                    </div>
                  </div>
                  <div class="w-80 rounded overflow-hidden shadow-lg transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300 relative">
                    <img class="w-full" src={kay} alt="" />
                    <div class="px-6 py-4 text-center">
                      <div class="font-bold text-xl mb-2">Ernest Aviani</div>
                      <p class="text-red-700 uppercase text-base">Head of IT Operations</p>
                    </div>
                  </div>
                </div>
              </div>
            }
            {showContent === 5 &&
              <div class={``}>
                <h1 class="text-red-900 font-['Tahoma'] font-bold uppercase text-center text-xl p-3">International Consultants</h1>
                <div className='flex justify-center items-end'>
                  <div className='w-[100px] h-[3px] rounded-lg bg-yellow-400 '></div>
                </div>
                <div class="pt-5 flex flex-wrap md:flex-row lg:flex-row justify-center items-center gap-10">
                  <div class="w-80 rounded overflow-hidden shadow-lg transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300 relative">
                    <img class="w-full" src={newton} alt="" />
                    <div class="px-6 py-4 text-center">
                      <div class="font-bold text-xl mb-2">Ellis Kelly Newton</div>
                      <p class="text-red-700 uppercase text-base">Fund Solicitor</p>
                    </div>
                  </div>
                  <div class="w-80 rounded overflow-hidden shadow-lg transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300 relative">
                    <img class="w-full" src={mulari} alt="" />
                    <div class="px-6 py-4 text-center">
                      <div class="font-bold text-xl mb-2">Mulani Bhagwan Gangadhar</div>
                      <p class="text-red-700 uppercase text-base">Sales / Marketer</p>
                    </div>
                  </div>
                </div>
              </div>
            }
            {showContent === 6 &&
              <div class={``}>
                <h1 class="text-red-900 font-['Tahoma'] font-bold uppercase text-center text-xl p-3">Advisory Consultants</h1>
                <div className='flex justify-center items-end'>
                  <div className='w-[100px] h-[3px] rounded-lg bg-yellow-400'></div>
                </div>
                <div class="pt-5 flex flex-wrap md:flex-row lg:flex-row justify-center items-center gap-10">
                  <div class="w-80 rounded overflow-hidden shadow-lg transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300 relative">
                    <img class="w-full" src={defaultimg} alt="" />
                    <div class="px-6 py-4 text-center">
                      <div class="font-bold text-xl mb-2">Lawyer PRempeh</div>
                      <p class="text-red-700 uppercase text-base">Legal Officer</p>
                    </div>
                  </div>
                  <div class="w-80 rounded overflow-hidden shadow-lg transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300 relative">
                    <img class="w-full" src={defaultimg} alt="" />
                    <div class="px-6 py-4 text-center">
                      <div class="font-bold text-xl mb-2">Samuel Atuah</div>
                      <p class="text-red-700 uppercase text-base">Senior Lawyer</p>
                    </div>
                  </div>
                  <div class="w-80 rounded overflow-hidden shadow-lg transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300 relative">
                    <img class="w-full" src={defaultimg} alt="" />
                    <div class="px-6 py-4 text-center">
                      <div class="font-bold text-xl mb-2">Samuel Kwashie Acolatse</div>
                      <p class="text-red-700 uppercase text-base">GNACOFA Counsellor</p>
                    </div>
                  </div>
                  <div class="w-80 rounded overflow-hidden shadow-lg transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300 relative">
                    <img class="w-full" src={defaultimg} alt="" />
                    <div class="px-6 py-4 text-center">
                      <div class="font-bold text-xl mb-2">Rass Boateng</div>
                      <p class="text-red-700 uppercase text-base">Business Counsellor</p>
                    </div>
                  </div>
                  <div class="w-80 rounded overflow-hidden shadow-lg transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300 relative">
                    <img class="w-full" src={defaultimg} alt="" />
                    <div class="px-6 py-4 text-center">
                      <div class="font-bold text-xl mb-2">Joseph Robertson Afful</div>
                      <p class="text-red-700 uppercase text-base">Business Consultant</p>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div> 
        </div>       
      </div>
    </section>
  )
}
export default Management

const buttomtabs = (showContent, setShowContent, id, title) => {
  return (
    <button className={`text-green-600 lg:w-[20vh] w-full text-start  ${showContent === id && 'lg:border-l-4 font-bold  lg:border-red-800 text-red-800'} p-2`} onClick={() => { setShowContent(id) }}> {title} </button>
  )
} 