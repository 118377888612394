import {React, useEffect} from 'react'
import { Carousel, ExecutiveSummary, YoutubeFeed, Event, Projects, Advert, Partner } from '../components'

const Home = () => {
  useEffect(() => {
      document.title = "Home - Welcome to GNACOFA"
  })
  return (
    <section className='relative'>
      {/* Image Carousel */}
      <Carousel />
      {/* Executive Summary */}
      <ExecutiveSummary />
      <YoutubeFeed />
      <Event />
      <Projects />
      {/* <Feedback /> */}
      <Advert />
      <Partner/>
    </section>
  )
}
export default Home
