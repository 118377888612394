import React from 'react'
import { useNavigate } from 'react-router-dom'
import Events from '../../constants/Events';
import News from '../../constants/News';

const EventAnnouncement = () => {
  const MonthName = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November','December']
  const date = new Date()
  const currentMonthIndex = date.getMonth();
  const currentMonth = MonthName[currentMonthIndex];
  const navigate = useNavigate();
  return (
    <section class="">
      <div class="container mx-auto lg:py-5 lg:px-32">
        <div class="text-center text-4xl text-green-700 font-semibold">
          <h1 class="font-['Gugi'] uppercase">Events & Announcements</h1>
          <div className='flex justify-center items-end'>
            <div className='w-[100px] h-[3px] rounded-lg bg-yellow-400 '></div>
          </div>
        </div>
        {/* content */}
        <div class="p-2 lg:flex lg:flex-row flex-col gap-x-2">
          <div class="lg:w-1/2 w-full">
            <h6 class="text-green-500 p-1 text-start text-base"><i class="fa-sharp fa-solid fa-radio mr-2"></i>News</h6><hr class="border-black" /><br />
            <div class="overflow-y-scroll h-[20rem]">
              <div className=''>
                {News.length > 0 || News ? (
                  News.map((news) => (
                    <buttom key={news.title} className='flex items-center gap-4 hover:bg-green-200 p-2 hover:text-black hover:rounded-lg hover:cursor-pointer' onClick={() => navigate(`/news/detail/${encodeURIComponent(news.title)}`)}>
                    <div>
                      <h3 class="text-green-700 text-sm font-semibold">{news.title}</h3>
                      <p class="text-green-500 text-xs">{news.content}</p>
                      <div className={`flex items-center gap-x-3 text-gray-300`}>
                        <p class="text-green-500 text-xs">{news.author}</p>
                        <p class="text-green-500 text-xs">Posted: {news.date_created}</p>
                      </div>
                    </div>
                    </buttom>
                  ))
                ) : (<h1 className=''>No News published for this month</h1>)}
              </div>
            </div>
          </div>
          <div class="lg:w-1/2 w-full">
            <h6 class="text-green-500 p-1 text-start text-base"><i class="fa-sharp fa-solid fa-radio mr-2"></i>Events & Activities <span className={`text-black`}>({currentMonth})</span></h6><hr class="border-black" /><br />
            <div class="overflow-y-scroll h-54">
              <div className=''>
                {Events.length > 0 ? (
                    Events.map((event) => (
                      event.date_created < date.getDate() ? (
                        <buttom key={event.title} className='flex items-center gap-4 hover:bg-green-200 p-2 hover:text-black hover:rounded-lg hover:cursor-pointer' onClick={() => navigate(`/dynamic/${encodeURIComponent(event.title)}`)}>
                          <div className='w-[20px] h-[20px] rounded-full bg-green-500'></div>
                          <div>
                            <h3 class="text-green-700 text-sm font-semibold">{event.title}</h3>
                            <p class="text-green-500 text-xs">{event.date} {event.time}</p>
                            <p class="text-green-500 text-xs">{event.location}</p>
                          </div>
                        </buttom>
                      ) : (
                        <h1 className={``}>No upcoming events for {currentMonth}</h1>
                      )
                    ))
                  ) : (
                    <div className={``}>
                      <h1 className={``}>No Scheduled Events for { currentMonth }</h1>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default EventAnnouncement
