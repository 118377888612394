import React from 'react'
import './topbar.css';
import brand_logo2 from '../../assets/icons/coat_of_arms_gnacofa_logo.png';
const Topbar = () => {
  return (
    <div className='hidden lg:block'>
      <div class="topbar">
        <div class="">
          <div className='bg-green-900'>
            <div class="inside-div flex lg:flex-row flex-wrap  bg-green-900 text-white p-5 justify-between items-center lg:gap-10 gap-4 container mx-auto px-36">
              <div className='flex just space-x-10'>
                <div class="info text-center">
                  <h1 class="font-bold"><i class="fa-solid fa-briefcase mr-2"></i>Working Hrs:</h1>
                  <p class="">Monday - Friday @ (8:00 am - 5:00)</p>
                </div>
                <div class="info text-center">
                  <h1 class="font-bold"><i class="fa-solid fa-envelope mr-2"></i>Email:</h1>
                  <p class=""><a href="mailto:info@gnacofa.org">info@gnacofa.org</a></p>
                </div>
                <div class="info text-center ">
                  <h1 class="font-bold"><i class="fa-solid fa-phone mr-2"></i>Hotlines:</h1>
                  <span class="">+233 (0) 32 219 4038 / +233 (0) 20 601 9419</span>
                </div>
              </div>
              
              <div class="flex items-center lg:flex-wrap justify-end gap-x-4">
                <h1 class="text-center font-bold text-lg lg:text-sm">Follow us:</h1>
                <div class=" flex gap-5 text-lg lg:text-lg">
                  <a href="#"><i class="fa-brands fa-whatsapp"></i></a>
                  <a href="#"><i class="fa-brands fa-twitter"></i></a>
                  <a href="#"><i class="fa-brands fa-facebook"></i></a>
                  <a href="#"><i class="fa-brands fa-youtube"></i></a>
                </div>
              </div>
            </div>
          </div>
          
          {/* <div class="p-1 lg:flex lg:justify-center items-center hidden lg:block ">
            <img class="im w-40 h-26 rounded-full p-1" src={brand_logo2} />
          </div> */}
          {/* <div class="under w-full p-4 container mx-auto lg:px-36 lg:block hidden">
            <div class="flex justify-center lg:justify-end items-center gap-5 text-black hover:text-green-600 font-bold text-lg divide-x pr-5">
              <h1><a href="https://portals.gnacofa.org"><i class="fa-solid fa-user-shield mr-1"></i>GNACOFA PORTAL SYSTEM</a></h1>
              {/* <h1><a href="https://staff.gnacofa.org"><i class="fa-solid fa-user-shield mr-1"></i>Staff Portal</a></h1> 
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}
export default Topbar