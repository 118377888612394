const Forms = [
  {
    title: "Membership Form",
    description: "Download and fill out.",
    link: `${process.env.PUBLIC_URL}/resources/essentials/GNACOFA BROCHURE.pdf`
  },
  {
    title: "Partnership Colaborative form",
    description: "Download and fill out.",
    link: `${process.env.PUBLIC_URL}/resources/essentials/GNACOFA BROCHURE.pdf`
  }
]
export default Forms;