import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player';
import './media.css'

export default function MediaGallery() {
  useEffect(() => {
    document.title = "Gallery";
  })
  const [selectedMedia, setSelectedMedia] = useState(null);
  
  return (
    <section>
      <div className="gallery lg:container lg:mx-auto">
        <div className="gallery-grid">
          {mediaData.map((media) => (
            <div key={media.id} className="gallery-item" onClick={() => setSelectedMedia(media)}>
              {media.type === 'image' ? (
                <img src={media.url} alt="Media" className="gallery-image" />
              ) : (
                <ReactPlayer url={media.url} className="gallery-video z-10" width="100%" height="100%" light />
              )}
            </div>
          ))}
        </div>
        {selectedMedia && (
          <div className="fixed inset-0 z-10 bg-black bg-opacity-50 backdrop-blur-0 border-2 flex justify-center items-center" onClick={() => setSelectedMedia(null)}>
            <div className="media-content" onClick={(e) => e.stopPropagation()}>
              {selectedMedia.type === 'image' ? (
                <img src={selectedMedia.url} alt="Media" />
              ) : (
                <ReactPlayer url={selectedMedia.url} controls autoplay width="300%" height="200%" />
              )}
            </div>
          </div>
        )}
      </div>
    </section>
  )
}

const mediaData = [
  { id: 1, type: 'image', url: 'https://via.placeholder.com/300x200' },
  { id: 2, type: 'image', url: 'https://via.placeholder.com/300x200' },
  { id: 3, type: 'video', url: 'https://www.w3schools.com/html/mov_bbb.mp4' },
  { id: 4, type: 'video', url: 'https://www.w3schools.com/html/mov_bbb.mp4' },
  { id: 5, type: 'image', url: 'https://via.placeholder.com/300x200' },
  { id: 6, type: 'image', url: 'https://via.placeholder.com/300x200' },
];