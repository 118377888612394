import React, {useEffect} from 'react'
import { useParams } from 'react-router-dom'
import projectsData from '../../constants/projects';

export default function ProjectDetails() {
  // useEffect(() => {
  //   document.title = "Home - Welcome to GNACOFA"
  // })
  
  const { title } = useParams();
  const project = projectsData.find((project) => project.title === decodeURIComponent(title));
  if (!project) {
    return <div>project not found</div>;
  }
  return (
    <div className='lg:container lg:mx-auto lg:px-36 p-3'>
      <div className='space-y-2'>
        <h1 className='text-center text-lg font-bold'>{project.title}</h1>
        <div className=''>
          <div className='w-full  rounded-lg'>
            <img src={project.image} alt={project.title} className='object-cover w-full lg:h-[40vh] rounded-lg' />
          </div>
          <div className='py-5'>
            <h2 className='text-lg font-semibold'>Description</h2>
            <p className='text-xl text-justify'>{project.description}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
