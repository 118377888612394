import React, { useEffect } from 'react'
import { welcome } from '../../assets'
import AnnualReportData from '../../constants/resources/AnnualReportsData';

export default function AnnualReport() {
  useEffect(() => {
    document.title = "GNACOFA - Annual Reports Data";
  })
  return (
    <section>
      <div class="lg:h-96 overflow-hidden relative lg:block hidden">
        <img src={welcome} class="block w-full  brightness-50" width="10%" alt="" />
      </div>
      <div className='lg:container lg:mx-auto lg:px-36 lg:p-10 p-2 flex justify-center items-center'>
        <div className=''>
          <h1 className='text-center font-bold text-3xl'>Annual Report Data</h1>
          <div className='p-2 lg:flex lg:justify-center lg:items-center grid grid-cols-1 gap-x-2'>
            {AnnualReportData.length > 0 ? (
              AnnualReportData.map((form, index) => (
                <div key={index} className='relative bg-[#6c2727] border-2 border-gray-200 p-5 lg:w-[40vh] w-[50svh] lg:h-[40vh] h-[40vh] space-y-5'>
                  <h2 className='text-xl font-bold text-[#fad669]'>{form.title}</h2>
                  <button className='absolute bottom-5 hover:text-green-500 text-white font-[Times New Romans] flex justify-between items-center gap-x-2 '>
                    <div className='flex items-center gap-x-2'>
                      <span class="material-symbols-outlined">picture_as_pdf</span>
                      <a href={form.link} download >Download {form.title}</a>
                    </div>
                    <span class="material-symbols-outlined">arrow_right_alt</span>
                  </button>
                </div>
              ))
            ) : (<h1 className=''>No forms available</h1>)}
          </div>
        </div>
      </div>
    </section>
  )
}
