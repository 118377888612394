
const Events = [
  {
    id: 1,
    title: 'GNACOFA Annual Meeting',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vel ipsum ac velit consectetur iaculis. Sed euismod, mauris in efficitur congue, neque velit facilisis velit, in ullamcorper neque velit id nunc.',
    date: '2023-05-15',
    time: '10:00 AM',
    location: 'Conference Room, Ghana National Agricultural Council',
  },
  {
    id: 2,
    title: 'GNACOFA Agricultural Fair',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vel ipsum ac velit consectetur iaculis. Sed euismod, mauris in efficitur congue, neque velit facilisis velit, in ullamcorper neque velit id nunc.',
    date: '2023-06-10',
    time: '9:00 AM',
    location: 'Conference Room, Ghana National Agricultural Council',
  },
  {
    id: 3,
    title: 'GNACOFA Fair & Exhibition',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vel ipsum ac velit consectetur iaculis. Sed euismod, mauris in efficitur congue, neque velit facilisis velit, in ullamcorper neque velit id nunc.',
    date: '2023-07-15',
    time: '12:00 PM',
    location: 'Conference Room, Ghana National Agricultural Council',
  }
]
export default Events;