const News = [
  {
    id: 1,
    title: 'Cocoa Production in Ashanti Region',
    content: 'The Ashanti region reported a significant increase in cocoa production.',
    date_created: '2023-01-15',
    author: 'GNACOFA News Inc',
    source: [
      {
        title: 'GNACOFA',
        url: 'www.gnacofa.com',
        date: '2023-01-15'
      }
    ]
  },
  {
    id: 2,
    title: 'Western Region Cocoa Yield Improvement',
    content: 'Western region farmers have improved their cocoa yield through better farming practices.',
    date_created: '2023-02-10',
    author: 'GNACOFA News Inc',
    source: [
      {
        title: 'GNACOFA',
        url: 'www.gnacofa.com',
        date: '2023-02-10'
      }
    ]
  },
  {
    id: 3,
    title: 'Cocoa Export Revenue Hits Record High',
    content: 'Ghana\'s cocoa export revenue reached a record high this year.',
    date_created: '2023-03-05',
    author: 'GNACOFA News Inc',
    source: [
      {
        title: 'GNACOFA',
        url: 'www.gnacofa.com',
        date: '2023-03-05'
      }
    ]
  },
  {
    id: 4,
    title: 'Innovations in Cocoa Farming in Central Region',
    content: 'New farming techniques have been introduced to increase cocoa production.',
    date_created: '2023-04-12',
    author: 'GNACOFA News Inc',
    source: [
      {
        title: 'GNACOFA',
        url: 'www.gnacofa.com',
        date: '2023-04-12'
      }
    ]
  },
  {
    id: 5,
    title: 'Brong-Ahafo Cocoa Farmers Receive Support',
    content: 'Cocoa farmers in Brong-Ahafo received support to boost their production.',
    date_created: '2023-05-20',
    author: 'GNACOFA News Inc',
    source: [
      {
        title: 'GNACOFA',
        url: 'www.gnacofa.com',
        date: '2023-05-20'
      }
    ]
  },
  {
    id: 6,
    title: 'Eastern Region Cocoa Production Decline',
    content: 'There has been a slight decline in cocoa production in the Eastern region.',
    date_created: '2023-06-18',
    author: 'GNACOFA News Inc',
    source: [
      {
        title: 'GNACOFA',
        url: 'www.gnacofa.com',
        date: '2023-06-18'
      }
    ]
  },
  {
    id: 7,
    title: 'Ghana Cocoa Industry Overview',
    content: 'An overview of the current state of the cocoa industry in Ghana.',
    date_created: '2023-07-25',
    author: 'GNACOFA News Inc',
    source: [
      {
        title: 'GNACOFA',
        url: 'www.gnacofa.com',
        date: '2023-07-25'
      }
    ]
  },
  {
    id: 8,
    title: 'Sustainable Cocoa Farming Practices',
    content: 'Adoption of sustainable practices is key to future cocoa productivity.',
    date_created: '2023-08-30',
    author: 'GNACOFA News Inc',
    source: [
      {
        title: 'GNACOFA',
        url: 'www.gnacofa.com',
        date: '2023-08-30'
      }
    ]
  },
  {
    id: 9,
    title: 'Cocoa Prices and Market Trends',
    content: 'Analysis of current cocoa prices and market trends.',
    date_created: '2023-09-14',
    author: 'GNACOFA News Inc',
    source: [
      {
        title: 'GNACOFA',
        url: 'www.gnacofa.com',
        date: '2023-09-14'
      }
    ]
  },
  {
    id: 10,
    title: 'Government Policies on Cocoa Export',
    content: 'Recent government policies affecting cocoa export.',
    date_created: '2023-10-10',
    author: 'GNACOFA News Inc',
    source: [
      {
        title: 'GNACOFA',
        url: 'www.gnacofa.com',
        date: '2023-10-10'
      }
    ]
  },
  {
    id: 11,
    title: 'Training Programs for Cocoa Farmers',
    content: 'Training programs aimed at improving cocoa farming skills.',
    date_created: '2023-11-05',
    author: 'GNACOFA News Inc',
    source: [
      {
        title: 'GNACOFA',
        url: 'www.gnacofa.com',
        date: '2023-11-05'
      }
    ]
  },
  {
    id: 12,
    title: 'Impact of Climate Change on Cocoa Production',
    content: 'The effects of climate change on cocoa production in Ghana.',
    date_created: '2023-12-15',
    author: 'GNACOFA News Inc',
    source: [
      {
        title: 'GNACOFA',
        url: 'www.gnacofa.com',
        date: '2023-12-15'
      }
    ]
  },
  {
    id: 13,
    title: 'Cocoa Industry Challenges and Opportunities',
    content: 'Exploring the challenges and opportunities in the cocoa industry.',
    date_created: '2024-01-20',
    author: 'GNACOFA News Inc',
    source: [
      {
        title: 'GNACOFA',
        url: 'www.gnacofa.com',
        date: '2024-01-20'
      }
    ]
  },
  {
    id: 14,
    title: 'Investment in Cocoa Processing Plants',
    content: 'New investments in cocoa processing plants in Ghana.',
    date_created: '2024-02-18',
    author: 'GNACOFA News Inc',
    source: [
      {
        title: 'GNACOFA',
        url: 'www.gnacofa.com',
        date: '2024-02-18'
      }
    ]
  },
  {
    id: 15,
    title: 'Technology in Cocoa Farming',
    content: 'The role of technology in improving cocoa farming.',
    date_created: '2024-03-22',
    author: 'GNACOFA News Inc',
    source: [
      {
        title: 'GNACOFA',
        url: 'www.gnacofa.com',
        date: '2024-03-22'
      }
    ]
  },
  {
    id: 16,
    title: 'Youth Involvement in Cocoa Farming',
    content: 'Encouraging youth to participate in cocoa farming.',
    date_created: '2024-04-30',
    author: 'GNACOFA News Inc',
    source: [
      {
        title: 'GNACOFA',
        url: 'www.gnacofa.com',
        date: '2024-04-30'
      }
    ]
  },
  {
    id: 17,
    title: 'Cocoa Farming Cooperatives',
    content: 'The benefits of cocoa farming cooperatives in Ghana.',
    date_created: '2024-05-15',
    author: 'GNACOFA News Inc',
    source: [
      {
        title: 'GNACOFA',
        url: 'www.gnacofa.com',
        date: '2024-05-15'
      }
    ]
  },
  {
    id: 18,
    title: 'Organic Cocoa Production',
    content: 'The rise of organic cocoa production in Ghana.',
    date_created: '2024-06-20',
    author: 'GNACOFA News Inc',
    source: [
      {
        title: 'GNACOFA',
        url: 'www.gnacofa.com',
        date: '2024-06-20'
      }
    ]
  },
  {
    id: 19,
    title: 'Financial Support for Cocoa Farmers',
    content: 'Financial institutions are providing support to cocoa farmers.',
    date_created: '2024-07-10',
    author: 'GNACOFA News Inc',
    source: [
      {
        title: 'GNACOFA',
        url: 'www.gnacofa.com',
        date: '2024-07-10'
      }
    ]
  },
  {
    id: 20,
    title: 'Cocoa Research and Development',
    content: 'Research and development initiatives to boost cocoa productivity.',
    date_created: '2024-08-05',
    author: 'GNACOFA News Inc',
    source: [
      {
        title: 'GNACOFA',
        url: 'www.gnacofa.com',
        date: '2024-08-05'
      }
    ]
  }
];

export default News;