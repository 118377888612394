const NewslettersData = [
  {
    title: "Newsletter 1",
    date: "June 1, 2022",
    link: `${process.env.PUBLIC_URL}/resources/newsletters/GNACOFA BROCHURE.pdf`,
    description: "This is the first newsletter of the Association.",
  },
  {
    title: "Newsletter 2",
    date: "May 1, 2022",
    link: `${process.env.PUBLIC_URL}/resources/newsletters/GNACOFA BROCHURE.pdf`,
    description: "This is the second newsletter of the Association.",
  },
  {
    title: "Newsletter 3",
    date: "April 1, 2022",
    link: `${process.env.PUBLIC_URL}/resources/newsletters/GNACOFA BROCHURE.pdf`,
    description: "This is the third newsletter of the Association.",
  },
  {
    title: "Newsletter 4",
    date: "March 1, 2022",
    link: `${process.env.PUBLIC_URL}/resources/newsletters/GNACOFA BROCHURE.pdf`,
    description: "This is the fourth newsletter of the Association.",
  },
  {
    title: "Newsletter 5",
    date: "February 1, 2022",
    link: `${process.env.PUBLIC_URL}/resources/newsletters/GNACOFA BROCHURE.pdf`,
    description: "This is the fifth newsletter of the Association.",
  }
]
export default NewslettersData;