import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { welcome } from '../../assets';
import News from '../../constants/News';
import image from '../../assets/images/grp.jpg';

export default function NewsPage() {
  useEffect(() => {
    document.title = "News";
  })
  const date = new Date();
  const Days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
  const MonthName = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
  const currentMonth = MonthName[date.getMonth()];
  const day = Days[date.getDay()-1]

  const navigate = useNavigate();
  const NewsSlice = News.slice(1, 3)
  const RestOfNews = News.slice(4, News.length - 1)
  const lastNewsInput = News[News.length - 1]
  return (
    <section>
      <div className='lg:container lg:mx-auto lg:px-36 px-2 overflow-hidden'>
        <h1 className='text-end text-gray-400 text-sm pt-10 pb-5  py-2 border-b'><span className='text-black'>Date:</span>{`${day}, (${date.getDate()}-${currentMonth}-${date.getFullYear()})`}</h1>
        <div className={``}>
          {
            News.length > 0 ? (
              <div className={`space-y-8`}>
                <div className={`flex flex-wrap-reverse gap-x-2`}>
                  <div className={``}>
                    {
                      NewsSlice.map((news, index) => (
                        <div key={news.title} className={``}>
                          <div className={``}>
                            <div className={`lg:w-[20vh]  overflow-hidden p-2 space-y-3`} onClick={() => navigate(`/news/detail/${encodeURIComponent(news.title)}`)}>
                              <img src={image} alt="newImage" className={`object-cover rounded-lg`} />
                              <div className={`text-black space-y-3`}>
                                <h2 onClick={() => navigate(`/news/detail/${encodeURIComponent(news.title)}`)} className={`text-sm font-bold cursor-pointer hover:text-gray-400`}>{news.title}</h2>
                                <p className={`text-sm`}>{news.content}</p>
                              </div>
                              <p onClick={() => navigate(`/news/detail/${encodeURIComponent(news.title)}`)} className='text-xs cursor-pointer text-red-500 hover:text-red-300'>Read more....</p>
                            </div>
                          </div>
                          <div className={``}></div>
                        </div>
                      ))
                    }
                  </div>
                  <div className={``}>
                    <div key={lastNewsInput.id} className={``}>
                      <div className={``}>
                        <div className={`lg:w-[38vh] overflow-hidden p-2 space-y-3`} onClick={() => navigate(`/news/detail/${encodeURIComponent(lastNewsInput.title)}`)}>
                          <img src={image} alt="newImage" className={` object-cover rounded-lg`} />
                          <div className={`text-black space-y-3`}>
                            <h2 onClick={() => navigate(`/news/detail/${encodeURIComponent(lastNewsInput.title)}`)} className={`text-sm font-bold cursor-pointer hover:text-gray-400`}>{lastNewsInput.title}</h2>
                            <p className={`text-sm`}>{lastNewsInput.content}</p>
                          </div>
                          <p onClick={() => navigate(`/news/detail/${encodeURIComponent(lastNewsInput.title)}`)} className='text-xs cursor-pointer text-red-500 hover:text-red-300'>Read more....</p>
                        </div>
                      </div>
                      <div className={``}></div>
                    </div>
                  </div>
                </div>
                <div className='mt-5'>
                  <h1 className=''>More News</h1>
                  <div className={`flex gap-x-2 overflow-x-scroll`}>
                    {
                      RestOfNews.map((news, index) => (
                        <div key={news.id} className={``}>
                          <div className={``}>
                            <div className={`w-[20vh] overflow-hidden p-2 space-y-3`} onClick={() => navigate(`/news/detail/${encodeURIComponent(news.title)}`)}>
                              <img src={image} alt="newImage" className={` object-cover rounded-lg`} />
                              <div className={`text-black space-y-3`}>
                                <h2 onClick={() => navigate(`/news/detail/${encodeURIComponent(news.title)}`)} className={`text-sm font-bold cursor-pointer hover:text-gray-400`}>{news.title}</h2>
                                <p className={`text-sm`}>{news.content}</p>
                              </div>
                              <p onClick={() => navigate(`/news/detail/${encodeURIComponent(news.title)}`)} className='text-xs cursor-pointer text-red-500 hover:text-red-300'>Read more....</p>
                            </div>
                          </div>
                          <div className={``}></div>
                        </div>
                      ))
                    }
                  </div>
                </div>
              </div>
              
            ) : (
              <h1>No news published</h1>
            )
          }
        </div>
      </div>
    </section>
  )
}